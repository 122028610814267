import cover from "../../assets/images/home/_00-Home-Hero video.mp4"
import scooterStreet from "../../assets/images/business/01_monthlyPass.jpg"
import smilesIcon from "../../assets/images/israel/illu_scooter_circle.svg"
import businessIcon from "../../assets/images/israel/illu_plant_circle.svg"

import scooterFirst from "../../assets/images/israel/step1.svg"
import scooterTwo from "../../assets/images/israel/step2.svg"
import scooterThree from "../../assets/images/israel/step3.svg"
import scooterFour from "../../assets/images/israel/step4.svg"
import scooterFive from "../../assets/images/israel/step5.svg"
import scooterSix from "../../assets/images/israel/step6.svg"
import scooterSeven from "../../assets/images/israel/step7.svg"
import scooterEight from "../../assets/images/israel/step8.svg"
import scooterNine from "../../assets/images/israel/step9.svg"

export const landingContent = {
  seo: {
    metaTitle: "TIER - change mobility for good.",
    metaDescription:
      "TIER - Change mobility for good. We work with cities, public transport operators and communities - for a clean and sustainable future.",
  },
  mainHero: {
    headline: "hebrewLandingMainHeroHeadline",
    subline: "hebrewLandingMainHeroSubline",
    type: "fullscreen",
    media: {
      type: "video",
      link: cover,
    },
  },
  textIcon: {
    headline: "hebrewLandingTextIconOneHeadline",
    description: "hebrewLandingTextIconOneDescription",
    backgroundColor: "background",
    textColor: "blue",
    iconPosition: "right",
    icon: businessIcon,
    alt: "tier-climate-neutral-icon",
  },
  textIconTwo: {
    headline: "hebrewLandingTextIconTwoHeadline",
    buttonText: "hebrewLandingTextIconTwoCta",
    buttonLink: "/where-to-find-us",
    backgroundColor: "background",
    textColor: "blue",
    iconPosition: "left",
    icon: smilesIcon,
    alt: "tier-woman-scooter-icon",
  },
  textImage: {
    headline: "hebrewLandingTextImageHeadline",
    description: "hebrewLandingTextImageDescription",
    media: { type: "image", link: scooterStreet, alt: "tier-women-riding-scooter" },
    type: "normal",
  },
  iconTabs: {
    headline: "hebrewLandingIconTabsHeadline",
    tabs: [
      {
        cta: "hebrewLandingIconTabsCta",
        ctaLink: "https://www.youtube.com/watch?v=pURTpH_-R6M",
        background: "bg-green-background",
        steps: [
          {
            icon: scooterFirst,
            description: "hebrewLandingIconTabsStepOne",
          },
          {
            icon: scooterTwo,
            description: "hebrewLandingIconTabsStepTwo",
          },
          {
            icon: scooterThree,
            description: "hebrewLandingIconTabsStepThree",
          },
          {
            icon: scooterFour,
            description: "hebrewLandingIconTabsStepFour",
          },
          {
            icon: scooterFive,
            description: "hebrewLandingIconTabsStepFive",
          },
          {
            icon: scooterSix,
            description: "hebrewLandingIconTabsStepSix",
          },
          {
            icon: scooterSeven,
            description: "hebrewLandingIconTabsStepSeven",
          },
          {
            icon: scooterEight,
            description: "hebrewLandingIconTabsStepEight",
          },
          {
            icon: scooterNine,
            description: "hebrewLandingIconTabsStepNine",
          },
        ],
      },
    ],
  },
  newsletterForm: {
    headline: "hebrewLandingNewsletterFormHeadline",
    consentText: "hebrewLandingNewsletterFormConsent",
    buttonText: "hebrewLandingNewsletterFormButton",
  },
}
