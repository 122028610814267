import React, { useState } from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

// Components
import Container from "../../components/containers/Container"
import PalButton from "../../components/buttons/PalButton"
import TextInput from "../../components/TextInput"
import Checkbox from "../../components/Checkbox"

const NewsletterForm = ({ content }) => {
  const { formatMessage } = useIntl()
  const [checked, setChecked] = useState(false)
  const [newsletterForm, setNewsletterForm] = useState({
    userEmail: "",
  })

  const { headline, consentText, buttonText } = content

  return (
    <Container fullWidth className="bg-blue flex items-center text-white py-20 md:py-40">
      <div className="flex flex-col max-w-xl m-auto z-10">
        <h5 className="text-h5 text-center font-extended w-full">{formatMessage({ id: headline })}</h5>
        <div className="flex flex-wrap space-y-8 md:space-y-0 md:gap-4 mt-12 md:mt-20">
          <TextInput
            form={newsletterForm}
            onInputChange={setNewsletterForm}
            field="userEmail"
            label="E-Mail"
            className="flex flex-grow w-full md:w-auto"
            dark
          />
          {/* TODO add subscribe action to button */}
          <PalButton
            className="w-full md:w-1/3 order-last md:order-2 mt-6 md:m-0"
            label={formatMessage({ id: buttonText })}
            color="secondary"
            disabled={!checked || !newsletterForm.userEmail.includes("@")}
          />
          <div className="flex w-full md:w-auto md:order-last md:pl-4 gap-x-2">
            <Checkbox className="text-white" modelValue={checked} onChange={() => setChecked(!checked)} />
            <p className="text-n3 text-white">{formatMessage({ id: consentText })}</p>
          </div>
        </div>
      </div>
    </Container>
  )
}

NewsletterForm.propTypes = {
  /**
   * Prop for the background color, passed as a tailwind class
   */
  content: PropTypes.object.isRequired,
}

export default NewsletterForm
