import React from "react"
import PropTypes from "prop-types"

function CheckIcon({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className="stroke-current"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M19.66 6.76L9.18 17.24 4.34 12.4"
      ></path>
    </svg>
  )
}

CheckIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CheckIcon.defaultProps = {
  color: "#0E1A50",
  width: 24,
  height: 24,
}

export default CheckIcon;
