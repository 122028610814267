import React, { useRef, useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import ChevronDown from "../../../components/icons/ChevronDown"
import { useClickOutside } from "../../../components/hooks/useClickOutside"
import { useIntl } from "gatsby-plugin-intl"

export const MovingTab = styled.div`
  height: 88px;
  transform: translateX(${({ $total, $position, $reversed }) => (50 + 100 / $total) * ($reversed ? -$position : $position)}%);

  transition: transform 0.3s ease-out;
`

export const Tab = ({ name, icon, ...props }) => {
  return (
    <div {...props} className="relative flex justify-center items-center gap-x-3 sm:gap-x-4 h-4/5 sm:h-full w-52 cursor-pointer">
      <img alt={"tier-" + name + "-icon"} src={icon} className="h-full sm:h-auto" />
      <p className="text-p2 text-blue font-bold">{name}</p>
    </div>
  )
}

Tab.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
}

export const TabDropdown = ({ options, selected, background, select, ...props }) => {
  const { formatMessage } = useIntl()
  const container = useRef(null)
  const [open, setOpen] = useState(false)

  useClickOutside(container, () => setOpen(false), open)

  return (
    <div {...props} ref={container} className="block sm:hidden sticky -mb-12 top-24 left-0 right-0 m-auto h-16 w-full">
      <div
        className={"relative z-10 flex justify-center items-center h-full w-full cursor-pointer shadow-sm rounded-lg  " + background}
        onClick={() => setOpen(!open)}
      >
        <ChevronDown className={(open ? "rotate-180" : "rotate-0") + " transform transition-transform absolute right-4 text-blue"} />
        <Tab name={formatMessage({ id: options[selected].title })} icon={options[selected].icon} />
      </div>
      <ul
        className={(!open ? "hidden " : "") + "w-full max-h-60 shadow-sm rounded-lg absolute overflow-scroll top-16 left-0 z-0 -mt-2 pt-8 bg-white"}
      >
        {options.map((option, i) => {
          if (option.title === options[selected].title) return
          return (
            <li
              key={option.title}
              className="flex justify-center mb-6"
              onClick={() => {
                select(i)
                setOpen(false)
              }}
            >
              <Tab name={formatMessage({ id: option.title })} icon={option.icon} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

TabDropdown.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.number,
  background: PropTypes.string,
  select: PropTypes.func,
}
