import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Container from "../../components/containers/Container"
import { MovingTab, Tab, TabDropdown } from "./TabBar/TabBar"
import SmallIconText from "../../components/SmallIconText"
import PalButton from "../../components/buttons/PalButton"
import { handleScrollToElement } from "../../utils/helpers"
import { useIntl } from "gatsby-plugin-intl"
import { useReverseLanguage } from "../../components/hooks/useReverseLanguage"

export const FadeEdge = styled.div`
  background: linear-gradient(180deg, #fffaf6 0%, #fffaf6 60%, rgba(255, 250, 246, 0) 100%);
`

const IconTabs = ({ content }) => {
  const { formatMessage } = useIntl()
  const [selected, setSelected] = useState(0)
  const { headline, tabs } = content
  let reverseLanguage = useReverseLanguage()

  const changeTab = i => {
    setSelected(i)
    handleScrollToElement("#how-to", 600)
  }

  return (
    <Container className="relative -mt-24 sm:-mt-36 mb-20" id="how-to">
      {headline && <h3 className="text-h1 text-blue text-center mb-20 mt-32">{formatMessage({ id: headline })}</h3>}
      {tabs.length > 1 && <FadeEdge className="sticky left-0 top-16 w-full h-36" />}
      {/* Tabbar Desktop */}
      {tabs.length > 1 && (
        <div className="sticky hidden sm:flex -mb-12 top-24 left-0 right-0 m-auto h-24 rounded-lg bg-white shadow-md w-min px-1">
          <MovingTab
            className={"absolute z-0 top-0 bottom-0 m-auto rounded-lg w-52 transition-all " + tabs[selected].tabColor}
            $total={tabs.length}
            $position={selected}
            $reversed={reverseLanguage}
          />
          {tabs.map(({ title, icon }, i) => (
            <Tab key={title} name={formatMessage({ id: title })} icon={icon} onClick={() => changeTab(i)} />
          ))}
        </div>
      )}
      {/* Dropdown Mobile */}
      {tabs.length > 1 && <TabDropdown background={tabs[selected].tabColor} options={tabs} selected={selected} select={changeTab} />}
      {/* Step List */}
      <div
        className={
          "w-full rounded-3xl flex flex-col items-center pt-28 pb-20 sm:pt-44 sm:pb-32 space-y-16 sm:space-y-32 " + tabs[selected].background
        }
      >
        {tabs[selected].steps.map((step, i) => (
          <SmallIconText
            key={"tier-instruction-" + "-" + (i + 1)}
            alt={"tier-instruction-" + "-" + (i + 1)}
            headline={step.title}
            icon={step.icon}
            description={step.description}
          />
        ))}
        <a href={tabs[selected].ctaLink} target="_blank" rel="noreferrer">
          <PalButton label={formatMessage({ id: tabs[selected].cta })} />
        </a>
      </div>
    </Container>
  )
}

IconTabs.propTypes = {
  /**
   * Prop for the background color, passed as a tailwind class
   */
  content: PropTypes.object.isRequired,
}

export default IconTabs
