/* eslint-disable no-useless-concat */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import PalValidationMessage from '../PalValidationMessage/PalValidationMessage'
import { classes } from '../utils/helpers'
import CheckIcon from "./icons/CheckIcon"
import ValidationMessage from './ValidationMessage/ValidationMessage'

/**
 * Basic Checkbox Field
 */
const Checkbox = ({ modelValue, label, disabled, conditions, onBlur, onFocus, onChange, ...props }) => {
  const { className, ...addonProps } = props
  const [focused, setFocused] = useState(false)
  const [checked, setChecked] = useState(false)

  const isInvalid = conditions.some((c) => c.isInvalid)

  const checkboxOptions = {
    'border-warning': isInvalid,
    'bg-gray-300 bg-opacity-20': focused
  }

  const onBlurAction = () => {
    if (onBlur) onBlur()

    setFocused(false)
  }

  const onFocusAction = () => {
    if (onFocus) onFocus()

    setFocused(true)
  }

  const onKeyUpAction = (e) => {
    if ((e.key === 'Enter' || e.key === 'Space') && onChange) {
      onChange()
    }
  }

  const toggleValue = (event) => {
    const isChecked = event.target.checked
    setChecked(isChecked)

    if (Array.isArray(modelValue)) {
      const newValue = isChecked
        ? [...modelValue, label]
        : modelValue.filter(val => val !== label)

      return onChange(newValue)
    }
    onChange(isChecked)
  }

  return (
    <div {...addonProps} className={className || ''}>
      <div className={(disabled ? 'opacity-40 pointer-events-none ' : '') + 'group relative flex items-center'}>
        <input
          id={label}
          className="outline-none h-5 w-5 cursor-pointer absolute opacity-0 z-10"
          type="checkbox"
          disabled={disabled}
          checked={checked}
          aria-checked={checked}
          value={label}
          onChange={toggleValue}
          onBlur={onBlurAction}
          onFocus={onFocusAction}
          onKeyUp={onKeyUpAction}
        />
        <span
          className={classes(checkboxOptions, 'flex items-center justify-center border w-5 h-5 relative border-gray-300 rounded group-hover:bg-gray-300 group-hover:bg-opacity-20 transition-colors')}
        >
          {checked && <CheckIcon width="16" height="16" />}
        </span>
        <label className=" flex-1 text-white cursor-pointer ml-2 select-none ">
          {label}
        </label>
      </div>
      {
        isInvalid && <ValidationMessage className="my-2" conditions={conditions} />
      }
    </div>
  )
}

Checkbox.propTypes = {
  /**
   * Parent values controlling the state of the checkbox
   */
  modelValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  /**
   * Identifier
   */
  label: PropTypes.string,
  /**
   * Control weather input should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * A list of conditions to be met for validty
   */
  conditions: PropTypes.array,
  /**
   * Optional unction to execute when the value of the input is toggled
   */
  onChange: PropTypes.func,
  /**
   * Optional function to execute when input is blured
   */
  onBlur: PropTypes.func,
  /**
   * Optional function to execute when the input is focused
   */
  onFocus: PropTypes.func
}

Checkbox.defaultProps = {
  modelValue: false,
  label: '',
  conditions: [],
  onChange: () => null,
  onBlur: () => null,
  onFocus: () => null
}

export default Checkbox
